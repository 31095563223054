var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "create-carrier" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.carrierDialog,
            callback: function ($$v) {
              _vm.carrierDialog = $$v
            },
            expression: "carrierDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticStyle: { "background-color": "#1e88e5" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "text-h2 white--text" }, [
                          _vm._v("Create Carrier"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Carrier Name",
                                      color: "info",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.alphaNumeric,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-office-building ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.carrier.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.carrier, "name", $$v)
                                      },
                                      expression: "carrier.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "float-left mt-2 mr-2",
                                      attrs: { color: "#1E88E5" },
                                    },
                                    [_vm._v(" mdi-domain ")]
                                  ),
                                  _c("customer-auto-complete"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "MC Number",
                                      color: "info",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.intDigit,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-contacts ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.carrier.mcNumber,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.carrier, "mcNumber", $$v)
                                      },
                                      expression: "carrier.mcNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "DOT Number",
                                      color: "info",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.intDigit,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-contacts ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.carrier.dotNumber,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.carrier, "dotNumber", $$v)
                                      },
                                      expression: "carrier.dotNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Phone",
                                      color: "info",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.phone,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-phone ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.carrier.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.carrier, "phone", $$v)
                                      },
                                      expression: "carrier.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Email",
                                      color: "info",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.email,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-email ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.carrier.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.carrier, "email", $$v)
                                      },
                                      expression: "carrier.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isNetworkTypeSelectShown
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Network Type",
                                          items: _vm.networkOptions,
                                          rules: [_vm.rules.required],
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#1E88E5",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-account-network-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3772257862
                                        ),
                                        model: {
                                          value: _vm.carrier.network,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.carrier,
                                              "network",
                                              $$v
                                            )
                                          },
                                          expression: "carrier.network",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "info", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCarrier()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }