var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Tooltip", {
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function () {
              return [
                _c("span", { staticClass: "initials" }, [
                  _vm._v(" " + _vm._s(_vm.initials) + " "),
                ]),
                _c("div", { staticClass: "chevronWrapper" }, [
                  _c(
                    "span",
                    { staticClass: "chevron" },
                    [
                      _c("icon", {
                        attrs: {
                          name: "ChevronDown",
                          color: _vm.colors["--dark-blue"],
                          size: "8",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "contentWrapper" },
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "mb-2",
                        attrs: { theme: "dropdown" },
                        on: {
                          click: _vm.openCarrierSalesRepresentativeDashboard,
                        },
                      },
                      [_vm._v(" Carrier Sales Representative Dashboard ")]
                    ),
                    _c(
                      "Button",
                      {
                        staticClass: "logoutButton",
                        attrs: { theme: "dropdown" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("Logout")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }