var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("div", { staticClass: "appBar" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "buttons" },
          [_c("AddButton", { staticClass: "mr-8" }), _c("Avatar")],
          1
        ),
      ]),
      _c("create-capacity"),
      _c("create-location"),
      _c("create-carrier"),
      _c("create-load"),
      _c("create-customer"),
      _c("create-factoring-company"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("@/assets/logo-mini.svg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }