var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "create-load" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.loadDialog,
            callback: function ($$v) {
              _vm.loadDialog = $$v
            },
            expression: "loadDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pt-0 pl-0",
                  staticStyle: { "background-color": "#1e88e5" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "12",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("span", { staticClass: "text-h2 white--text" }, [
                            _vm._v("Create Load"),
                          ]),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c("v-img", {
                                attrs: {
                                  src: require("@/assets/create-load-box.svg"),
                                  height: "120",
                                  width: "120",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "load-form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { left: "", color: "#1E88E5" },
                                        },
                                        [_vm._v(" mdi-account-circle ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "11" } },
                                [_c("customer-auto-complete")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.modes,
                                      label: "Mode",
                                      "item-text": "name",
                                      "item-value": "id",
                                      dense: "",
                                      solo: "",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.load.mode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.load, "mode", $$v)
                                      },
                                      expression: "load.mode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.orderTypes,
                                      label: "Order Type",
                                      "item-text": "name",
                                      "item-value": "id",
                                      dense: "",
                                      solo: "",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.load.orderType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.load, "orderType", $$v)
                                      },
                                      expression: "load.orderType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.equipmentTypes,
                                      label: "Equipment",
                                      "item-text": "name",
                                      "item-value": "id",
                                      dense: "",
                                      solo: "",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.load.equipment,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.load, "equipment", $$v)
                                      },
                                      expression: "load.equipment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("location-auto-complete", {
                                    attrs: {
                                      "form-type": "1",
                                      direction: "source",
                                    },
                                  }),
                                  _c("location-auto-complete", {
                                    attrs: {
                                      "form-type": "1",
                                      direction: "destination",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column mb-4 mt-1",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            center: "",
                                            small: "",
                                            color: "green",
                                          },
                                        },
                                        [_vm._v(" mdi-calendar ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            center: "",
                                            small: "",
                                            color: "red",
                                          },
                                        },
                                        [_vm._v(" mdi-calendar ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: { cols: "11", md: "5" },
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        label: "Pickup Date",
                                                        rules: [
                                                          _vm.rules.required,
                                                        ],
                                                        "persistent-hint": "",
                                                        readonly: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.load.pickupDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.load,
                                                            "pickupDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "load.pickupDate",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.pickupCalendar,
                                        callback: function ($$v) {
                                          _vm.pickupCalendar = $$v
                                        },
                                        expression: "pickupCalendar",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: { "no-title": "" },
                                        on: {
                                          input: function ($event) {
                                            _vm.pickupCalendar = false
                                          },
                                        },
                                        model: {
                                          value: _vm.load.pickupDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.load,
                                              "pickupDate",
                                              $$v
                                            )
                                          },
                                          expression: "load.pickupDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: {
                                                        label: "Delivery Date",
                                                        rules: [
                                                          _vm.rules.required,
                                                        ],
                                                        "persistent-hint": "",
                                                        readonly: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.load
                                                            .destinationDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.load,
                                                            "destinationDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "load.destinationDate",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.destinationCalendar,
                                        callback: function ($$v) {
                                          _vm.destinationCalendar = $$v
                                        },
                                        expression: "destinationCalendar",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: { "no-title": "" },
                                        on: {
                                          input: function ($event) {
                                            _vm.destinationCalendar = false
                                          },
                                        },
                                        model: {
                                          value: _vm.load.destinationDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.load,
                                              "destinationDate",
                                              $$v
                                            )
                                          },
                                          expression: "load.destinationDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _vm.$vuetify.breakpoint.mdAndUp
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "1" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color: "#1E88E5",
                                                  },
                                                },
                                                [_vm._v(" mdi-cube-send ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: { cols: "10" },
                                        },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mx-2 pt-0",
                                            attrs: {
                                              label: "Commodity",
                                              rows: "3",
                                            },
                                            model: {
                                              value: _vm.load.commodity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.load,
                                                  "commodity",
                                                  $$v
                                                )
                                              },
                                              expression: "load.commodity",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _vm.$vuetify.breakpoint.mdAndUp
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "1" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color: "#1E88E5",
                                                  },
                                                },
                                                [_vm._v(" mdi-currency-usd ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: { cols: "10" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pt-0",
                                            attrs: {
                                              label: "Customer Price",
                                              color: "info",
                                              rules: [_vm.rules.amount],
                                            },
                                            model: {
                                              value: _vm.load.customerPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.load,
                                                  "customerPrice",
                                                  $$v
                                                )
                                              },
                                              expression: "load.customerPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }, [
                                        _vm._v("   "),
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: { cols: "10" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pt-0",
                                            attrs: {
                                              label: "Currier Min",
                                              color: "info",
                                              rules: [_vm.rules.amount],
                                            },
                                            model: {
                                              value: _vm.load.currierMin,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.load,
                                                  "currierMin",
                                                  $$v
                                                )
                                              },
                                              expression: "load.currierMin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }, [
                                        _vm._v("   "),
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: { cols: "10" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pt-0",
                                            attrs: {
                                              label: "Currier Max",
                                              color: "info",
                                              rules: [_vm.rules.amount],
                                            },
                                            model: {
                                              value: _vm.load.currierMax,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.load,
                                                  "currierMax",
                                                  $$v
                                                )
                                              },
                                              expression: "load.currierMax",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "rounded",
                                    staticStyle: {
                                      "background-color": "#f6f6f6",
                                      height: "256px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-subtitle",
                                      {
                                        staticClass:
                                          "text--lighten-2 pl-2 box-header",
                                      },
                                      [_vm._v(" Comments ")]
                                    ),
                                    _c("v-textarea", {
                                      staticClass: "mx-1",
                                      staticStyle: {
                                        "background-color": "#fff",
                                        resize: "none",
                                      },
                                      attrs: { rows: "9" },
                                      model: {
                                        value: _vm.load.comments,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.load, "comments", $$v)
                                        },
                                        expression: "load.comments",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "info", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitLoad()
                        },
                      },
                    },
                    [_vm._v(" Create ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }