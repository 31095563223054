var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "create-factoring-company" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.factoringDialog,
            callback: function ($$v) {
              _vm.factoringDialog = $$v
            },
            expression: "factoringDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticStyle: { "background-color": "#1e88e5" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "text-h2 white--text" }, [
                          _vm._v("Create Factoring Company"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1 d",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Name",
                                      color: "info",
                                      rules: [
                                        _vm.rules.alphaNumeric,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-contacts ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.businessName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.factoring,
                                          "businessName",
                                          $$v
                                        )
                                      },
                                      expression: "factoring.businessName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Address",
                                      color: "info",
                                      rules: [
                                        _vm.rules.alphaNumericWithComma,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-road ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.addressLine1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.factoring,
                                          "addressLine1",
                                          $$v
                                        )
                                      },
                                      expression: "factoring.addressLine1",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "City",
                                      color: "info",
                                      rules: [
                                        _vm.rules.alphaNumeric,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-road ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.factoring, "city", $$v)
                                      },
                                      expression: "factoring.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "State",
                                      color: "info",
                                      rules: [
                                        _vm.rules.alphaNumeric,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-road ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.factoring, "state", $$v)
                                      },
                                      expression: "factoring.state",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Postal Code",
                                      color: "info",
                                      rules: [
                                        _vm.rules.postalCode,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-road ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.postalCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.factoring,
                                          "postalCode",
                                          $$v
                                        )
                                      },
                                      expression: "factoring.postalCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.countries,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Country",
                                      color: "info",
                                      rules: [_vm.rules.required],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-map ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.country,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.factoring, "country", $$v)
                                      },
                                      expression: "factoring.country",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Phone",
                                      color: "info",
                                      rules: [
                                        _vm.rules.phone,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-phone ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.factoring, "phone", $$v)
                                      },
                                      expression: "factoring.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Email",
                                      color: "info",
                                      rules: [
                                        _vm.rules.email,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-email ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.factoring, "email", $$v)
                                      },
                                      expression: "factoring.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-1",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Contact Name",
                                      color: "info",
                                      rules: [_vm.rules.name],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-account ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.factoring.contactName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.factoring,
                                          "contactName",
                                          $$v
                                        )
                                      },
                                      expression: "factoring.contactName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "info", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitFactoringCompany()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }