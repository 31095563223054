var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("AppBar"),
      _c("Drawer"),
      _c("Content"),
      _c(
        "v-alert",
        {
          staticStyle: {
            "z-index": "9999",
            right: "0",
            bottom: "20px",
            position: "fixed",
          },
          attrs: {
            value: _vm.snackbar.status,
            type: _vm.snackbar.type,
            transition: "slide-x-transition",
            prominent: "",
          },
        },
        [
          _c("b", { staticStyle: { "font-size": "14px" } }, [
            _vm._v(_vm._s(_vm.snackbar.text)),
          ]),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.closeAlert()
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close-circle")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }