var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Tooltip", {
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function () {
              return [
                _c("Button", {
                  attrs: {
                    theme: "attention",
                    size: "small",
                    "icon-left": "PlusCircle",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "contentWrapper" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("div", { staticClass: "menuTitle" }, [
                        _vm._v("General"),
                      ]),
                      _c(
                        "Button",
                        {
                          attrs: { theme: "dropdown" },
                          on: { click: _vm.openCapacityForm },
                        },
                        [_vm._v(" Capacity ")]
                      ),
                      _c(
                        "Button",
                        {
                          attrs: { theme: "dropdown" },
                          on: { click: _vm.openLoadForm },
                        },
                        [_vm._v("Load")]
                      ),
                      _c(
                        "Button",
                        {
                          attrs: { theme: "dropdown" },
                          on: { click: _vm.openLocationForm },
                        },
                        [_vm._v(" Location ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("div", { staticClass: "menuTitle" }, [
                        _vm._v("Account"),
                      ]),
                      _c(
                        "Button",
                        {
                          attrs: { theme: "dropdown" },
                          on: { click: _vm.openCustomerForm },
                        },
                        [_vm._v(" Customer ")]
                      ),
                      _c(
                        "Button",
                        {
                          attrs: { theme: "dropdown" },
                          on: { click: _vm.openCarrierForm },
                        },
                        [_vm._v("Carrier")]
                      ),
                      _c(
                        "Button",
                        {
                          attrs: { theme: "dropdown" },
                          on: { click: _vm.openFactoringForm },
                        },
                        [_vm._v(" Factoring ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }