var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.to ? "router-link" : "a",
    {
      tag: "component",
      staticClass: "DrawerMenuItem",
      attrs: { to: _vm.to, href: _vm.href, target: _vm.target },
    },
    [
      _c("icon", {
        staticClass: "icon",
        attrs: {
          name: _vm.iconName,
          color: _vm.colors["--dark-blue"],
          size: "20",
        },
      }),
      _c("span", { staticClass: "label" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }