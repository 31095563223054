var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wrapper",
      style: { width: _vm.drawerWidth },
      on: {
        mouseover: function ($event) {
          _vm.isOpen = true
        },
        mouseleave: function ($event) {
          _vm.isOpen = false
        },
      },
    },
    _vm._l(_vm.menuItems, function (item) {
      return _c(
        "div",
        { key: item.label },
        [
          item.access
            ? _c("MenuItem", {
                attrs: {
                  iconName: item.iconName,
                  label: item.label,
                  to: item.to,
                  href: item.href,
                  target: item.target,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }