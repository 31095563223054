var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "500" },
      model: {
        value: _vm.locationDialog,
        callback: function ($$v) {
          _vm.locationDialog = $$v
        },
        expression: "locationDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "create-location-dialog" } },
        [
          _c(
            "v-card-title",
            { staticStyle: { "background-color": "#1e88e5" } },
            [
              _c(
                "v-row",
                { staticClass: "align-center" },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "text-h2 white--text" }, [
                      _vm._v("Create Location"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0 pb-2", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Location Name",
                                  color: "info",
                                  "data-cy": "location-name",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.alphaNumeric,
                                  ],
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mt-2",
                                            attrs: { color: "#1E88E5" },
                                          },
                                          [_vm._v("mdi-office-building")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.location.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "name", $$v)
                                  },
                                  expression: "location.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex py-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "#1E88E5" },
                                },
                                [_vm._v("mdi-domain")]
                              ),
                              _c("customer-auto-complete", {
                                staticClass: "flex-grow-1",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Contact Name",
                                  color: "info",
                                  rules: [_vm.rules.alphaNumeric],
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "#1E88E5" } },
                                          [_vm._v("mdi-contacts")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.location.contactName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "contactName", $$v)
                                  },
                                  expression: "location.contactName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Phone",
                                  color: "info",
                                  rules: [_vm.rules.phone],
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "#1E88E5" } },
                                          [_vm._v("mdi-phone")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.location.contactPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "contactPhone", $$v)
                                  },
                                  expression: "location.contactPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Hours", color: "info" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "#1E88E5" } },
                                          [_vm._v("mdi-clock")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.location.hours,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "hours", $$v)
                                  },
                                  expression: "location.hours",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-start py-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-6 mr-2",
                                  attrs: { color: "#1E88E5" },
                                },
                                [_vm._v("mdi-city")]
                              ),
                              _c("detail-location-auto-complete", {
                                staticClass: "flex-grow-1",
                                on: { event: _vm.setPlace },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Notes",
                                  counter: "",
                                  maxlength: "300",
                                  color: "info",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "#1E88E5" } },
                                          [_vm._v("mdi-note")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.location.notes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.location, "notes", $$v)
                                  },
                                  expression: "location.notes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "info",
                    text: "",
                    "data-cy": "location-submit-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitLocation()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }