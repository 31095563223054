var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wrapper",
      on: {
        mouseover: function ($event) {
          _vm.isShown = true
        },
        mouseleave: function ($event) {
          _vm.isShown = false
        },
      },
    },
    [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              _vm.isShown = true
            },
          },
        },
        [_vm._t("trigger")],
        2
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isShown
          ? _c("div", { staticClass: "contentWrapper" }, [
              _c("div", { staticClass: "contentBackground" }, [
                _c("div", {
                  staticClass: "tip",
                  style: { backgroundColor: _vm.tipColor },
                }),
                _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }