var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "create-customer" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.customerDialog,
            callback: function ($$v) {
              _vm.customerDialog = $$v
            },
            expression: "customerDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticStyle: { "background-color": "#1e88e5" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "text-h2 white--text" }, [
                          _vm._v("Create Customer"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0", attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.name,
                                      ],
                                      color: "info",
                                      label: "Name",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-account ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.customer.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "name", $$v)
                                      },
                                      expression: "customer.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.alphaNumeric,
                                      ],
                                      color: "info",
                                      label: "Address",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-map-marker ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.customer.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "address", $$v)
                                      },
                                      expression: "customer.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.name,
                                      ],
                                      color: "info",
                                      label: "City",
                                    },
                                    model: {
                                      value: _vm.customer.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "city", $$v)
                                      },
                                      expression: "customer.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.name,
                                      ],
                                      color: "info",
                                      hint: "Short state name (Ex. Texas - TX)",
                                      label: "State",
                                    },
                                    model: {
                                      value: _vm.customer.state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "state", $$v)
                                      },
                                      expression: "customer.state",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      items: _vm.countries,
                                      rules: [_vm.rules.required],
                                      color: "info",
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Country",
                                    },
                                    model: {
                                      value: _vm.customer.country,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "country", $$v)
                                      },
                                      expression: "customer.country",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.postalCode,
                                      ],
                                      color: "info",
                                      label: "Postal Code",
                                    },
                                    model: {
                                      value: _vm.customer.zipCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "zipCode", $$v)
                                      },
                                      expression: "customer.zipCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.phone,
                                      ],
                                      color: "info",
                                      label: "Phone",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-phone ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.customer.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "phone", $$v)
                                      },
                                      expression: "customer.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.email,
                                      ],
                                      color: "info",
                                      label: "Email",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-email ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.customer.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "email", $$v)
                                      },
                                      expression: "customer.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "float-left mt-2",
                                      attrs: { color: "#1E88E5", left: "" },
                                    },
                                    [_vm._v(" mdi-home ")]
                                  ),
                                  _c("agent-auto-complete", {
                                    attrs: { "agent-text": "Account Owner" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0 mt-n3",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("account-manager-auto-complete", {
                                    attrs: {
                                      "default-manager":
                                        _vm.customer.accountManager,
                                      color: "#e1b753",
                                    },
                                    on: {
                                      blur: _vm.selectedAccountManager,
                                      event: _vm.selectedAccountManager,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      items: _vm.orgLevels,
                                      color: "info",
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Organization Level",
                                    },
                                    model: {
                                      value: _vm.customer.orgLevel,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.customer, "orgLevel", $$v)
                                      },
                                      expression: "customer.orgLevel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0 pb-0",
                                      attrs: { cols: "1" },
                                    },
                                    [_vm._v("   ")]
                                  )
                                : _vm._e(),
                              _vm.customer.orgLevel == 2
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0 pb-0",
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c("customer-auto-complete", {
                                        attrs: { "customer-text": "Parent" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "info", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCustomer()
                        },
                      },
                    },
                    [_vm._v(" Create ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }