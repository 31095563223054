var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "500" },
      model: {
        value: _vm.capacityDialog,
        callback: function ($$v) {
          _vm.capacityDialog = $$v
        },
        expression: "capacityDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticStyle: { "background-color": "#1e88e5" } },
            [
              _c(
                "v-row",
                { staticClass: "align-center" },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "text-h2 white--text" }, [
                      _vm._v("Create Capacity"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.types,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Type",
                                },
                                on: { change: _vm.changeMatchType },
                                model: {
                                  value: _vm.capacity.matchType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.capacity, "matchType", $$v)
                                  },
                                  expression: "capacity.matchType",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-1",
                                      attrs: { row: "", mandatory: "" },
                                      on: {
                                        change: _vm.changeCarrierSelection,
                                      },
                                      model: {
                                        value: _vm.row,
                                        callback: function ($$v) {
                                          _vm.row = $$v
                                        },
                                        expression: "row",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Existing Carrier",
                                          value: "1",
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "New Carrier",
                                          value: "2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: _vm.isQuote ? 6 : 12 },
                            },
                            [
                              _vm.isExistingCarrier
                                ? _c(
                                    "v-autocomplete",
                                    {
                                      staticClass: "mt-n2",
                                      attrs: {
                                        items: _vm.carrierList,
                                        loading: _vm.carrierLoading,
                                        "search-input": _vm.carrierSearch,
                                        color: "info",
                                        "item-text": "Name",
                                        "item-value": "id",
                                        label: "Carrier",
                                        placeholder: "Start typing to Search",
                                        "return-object": "",
                                        rules: [
                                          (v) => !!v || "Carrier is required",
                                        ],
                                        "validate-on-blur": "",
                                        "close-on-content-click": "",
                                      },
                                      on: {
                                        "update:searchInput": function (
                                          $event
                                        ) {
                                          _vm.carrierSearch = $event
                                        },
                                        "update:search-input": function (
                                          $event
                                        ) {
                                          _vm.carrierSearch = $event
                                        },
                                        change: _vm.selectedCarrier,
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return $event.stopPropagation()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "#1E88E5" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-office-building "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2856080642
                                      ),
                                      model: {
                                        value: _vm.capacity.carrier,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.capacity, "carrier", $$v)
                                        },
                                        expression: "capacity.carrier",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { slot: "append-item" },
                                          slot: "append-item",
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.carrierForm()
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                              _vm._v(" Add carrier "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c("v-text-field", {
                                    staticClass: "mt-n2",
                                    attrs: {
                                      label: "Carrier Name",
                                      color: "info",
                                      type: "text",
                                      rules: [
                                        _vm.rules.name,
                                        _vm.rules.required,
                                      ],
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1E88E5" } },
                                              [_vm._v(" mdi-office-building ")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.capacity.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.capacity, "name", $$v)
                                      },
                                      expression: "capacity.name",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: {
                                    cols: "12",
                                    md: _vm.isQuote ? 6 : 12,
                                  },
                                },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "mt-n2",
                                    attrs: {
                                      items: _vm.loadList,
                                      loading: _vm.loadLoading,
                                      "search-input": _vm.loadSearch,
                                      color: "info",
                                      clearable: "",
                                      "item-text": "Name",
                                      "item-value": "id",
                                      label: "Load",
                                      placeholder: "Start typing to Search",
                                      "return-object": "",
                                      required: "",
                                      rules: [(v) => !!v || "Load is required"],
                                      "validate-on-blur": "",
                                      "close-on-content-click": "",
                                    },
                                    on: {
                                      "update:searchInput": function ($event) {
                                        _vm.loadSearch = $event
                                      },
                                      "update:search-input": function ($event) {
                                        _vm.loadSearch = $event
                                      },
                                      change: _vm.loadSelected,
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return $event.stopPropagation()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#1E88E5" } },
                                                [
                                                  _vm._v(
                                                    " mdi-package-variant-closed "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2821122726
                                    ),
                                    model: {
                                      value: _vm.selectedLoad,
                                      callback: function ($$v) {
                                        _vm.selectedLoad = $$v
                                      },
                                      expression: "selectedLoad",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isQuote
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "6" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-input pt-0 theme--light v-text-field v-text-field--is-booted",
                                    },
                                    [
                                      _vm.$vuetify.breakpoint.lgAndUp
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "pr-2",
                                              attrs: { color: "green" },
                                            },
                                            [_vm._v(" mdi-map-marker ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "v-input__control" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "v-input__slot" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "v-text-field__slot",
                                                },
                                                [
                                                  _c("gmap-autocomplete", {
                                                    ref: "autocompleteOrigin",
                                                    attrs: {
                                                      placeholder:
                                                        "Source Location",
                                                      options: {
                                                        fields: [
                                                          "address_components",
                                                          "geometry",
                                                        ],
                                                      },
                                                    },
                                                    on: {
                                                      place_changed:
                                                        _vm.setOriginPlace,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "input",
                                                          fn: function (
                                                            slotProps
                                                          ) {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  ref: "input",
                                                                  attrs: {
                                                                    label:
                                                                      "Origin",
                                                                    placeholder:
                                                                      "Origin",
                                                                    "prepend-icon":
                                                                      "mdi-map-marker",
                                                                  },
                                                                  on: {
                                                                    listeners:
                                                                      slotProps.listeners,
                                                                    attrs:
                                                                      slotProps.attrs,
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2171681364
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.isQuote
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "6" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-input pt-0 theme--light v-text-field v-text-field--is-booted",
                                    },
                                    [
                                      _vm.$vuetify.breakpoint.lgAndUp
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "pr-2",
                                              attrs: { color: "red" },
                                            },
                                            [_vm._v(" mdi-map-marker ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "v-input__control" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "v-input__slot" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "v-text-field__slot",
                                                },
                                                [
                                                  _c("gmap-autocomplete", {
                                                    ref: "autocompleteDestination",
                                                    attrs: {
                                                      placeholder:
                                                        "Destination Location",
                                                      options: {
                                                        fields: [
                                                          "address_components",
                                                          "geometry",
                                                        ],
                                                      },
                                                    },
                                                    on: {
                                                      place_changed:
                                                        _vm.setDestinationPlace,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.isQuote
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "6" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      attrs: {
                                        "close-on-content-click": true,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "290px",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Available Date *",
                                                        readonly: "",
                                                        color: "info",
                                                        rules: [
                                                          (v) =>
                                                            !!v ||
                                                            "Available date is required",
                                                        ],
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "prepend",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#1E88E5",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-calendar "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.capacity
                                                            .sourceDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.capacity,
                                                            "sourceDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "capacity.sourceDate",
                                                      },
                                                    },
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        213825308
                                      ),
                                      model: {
                                        value: _vm.sourceDateMenu,
                                        callback: function ($$v) {
                                          _vm.sourceDateMenu = $$v
                                        },
                                        expression: "sourceDateMenu",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            "no-title": "",
                                            scrollable: "",
                                          },
                                          model: {
                                            value: _vm.capacity.sourceDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.capacity,
                                                "sourceDate",
                                                $$v
                                              )
                                            },
                                            expression: "capacity.sourceDate",
                                          },
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "info",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.menu = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "info",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.menu.save(
                                                    _vm.date
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" OK ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isQuote
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "6" } },
                                [
                                  !_vm.isQuote
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.equipmentTypes,
                                          label: "Equipment Type *",
                                          "item-text": "name",
                                          "item-value": "id",
                                          required: "",
                                          color: "info",
                                          rules: [
                                            (v) =>
                                              !!v || "Equipment is required",
                                          ],
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return $event.stopPropagation()
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#1E88E5",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-truck-trailer "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          372811834
                                        ),
                                        model: {
                                          value: _vm.capacity.equipmentType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.capacity,
                                              "equipmentType",
                                              $$v
                                            )
                                          },
                                          expression: "capacity.equipmentType",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12", sm: "4" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Qty Trucks *",
                                      required: "",
                                      color: "info",
                                      "validate-on-blur": "",
                                      rules: [
                                        (v) =>
                                          !!v ||
                                          "Needs to be at least one truck",
                                      ],
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function () {
                                            return [
                                              !_vm.isQuote
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#1E88E5",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-truck-check-outline "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      736305914
                                    ),
                                    model: {
                                      value: _vm.capacity.qtyTrucks,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.capacity, "qtyTrucks", $$v)
                                      },
                                      expression: "capacity.qtyTrucks",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12", sm: "4" },
                                },
                                [
                                  !_vm.isQuote
                                    ? _c("v-text-field", {
                                        attrs: {
                                          label: "Qty Loads",
                                          color: "info",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend",
                                              fn: function () {
                                                return [
                                                  !_vm.isQuote
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#1E88E5",
                                                          },
                                                        },
                                                        [_vm._v(" mdi-dolly ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1175913589
                                        ),
                                        model: {
                                          value: _vm.capacity.qtyLoads,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.capacity,
                                              "qtyLoads",
                                              $$v
                                            )
                                          },
                                          expression: "capacity.qtyLoads",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Email",
                                      color: "info",
                                      rules: [_vm.rules.email],
                                      disabled: _vm.isExistingCarrier,
                                      "validate-on-blur": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#1E88E5" } },
                                                [_vm._v(" mdi-email ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      140416085
                                    ),
                                    model: {
                                      value: _vm.capacity.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.capacity, "email", $$v)
                                      },
                                      expression: "capacity.email",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      attrs: {
                                        label: "Phone",
                                        color: "info",
                                        rules: [_vm.rules.phone],
                                        disabled: _vm.isExistingCarrier,
                                        "validate-on-blur": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "#1E88E5" },
                                                  },
                                                  [_vm._v(" mdi-phone ")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3991200581
                                      ),
                                      model: {
                                        value: _vm.capacity.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.capacity, "phone", $$v)
                                        },
                                        expression: "capacity.phone",
                                      },
                                    },
                                    [_vm._v(" > ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "DOT Number",
                                      color: "info",
                                      rules: !_vm.capacity.mcNumber
                                        ? [
                                            _vm.rules.intDigit,
                                            _vm.rules.required,
                                          ]
                                        : [_vm.rules.intDigit],
                                      disabled: _vm.isExistingCarrier,
                                      "validate-on-blur": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#1E88E5" } },
                                                [_vm._v(" mdi-barcode ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3442171077
                                    ),
                                    model: {
                                      value: _vm.capacity.dotNumber,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.capacity, "dotNumber", $$v)
                                      },
                                      expression: "capacity.dotNumber",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "MC Number",
                                      color: "info",
                                      rules: !_vm.capacity.dotNumber
                                        ? [
                                            _vm.rules.intDigit,
                                            _vm.rules.required,
                                          ]
                                        : [_vm.rules.intDigit],
                                      disabled: _vm.isExistingCarrier,
                                      "validate-on-blur": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#1E88E5" } },
                                                [_vm._v(" mdi-barcode ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3442171077
                                    ),
                                    model: {
                                      value: _vm.capacity.mcNumber,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.capacity, "mcNumber", $$v)
                                      },
                                      expression: "capacity.mcNumber",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "sols pb-0 pt-0",
                              attrs: { sm: _vm.isQuote ? 6 : 4 },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Rate",
                                  color: "info",
                                  type: "number",
                                  rules: [_vm.rules.amount, _vm.rules.required],
                                  "validate-on-blur": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "#1E88E5" } },
                                          [_vm._v(" mdi-currency-usd ")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.capacity.rate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.capacity, "rate", $$v)
                                  },
                                  expression: "capacity.rate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isQuote
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "sols pb-0 pt-0",
                                  attrs: { sm: _vm.isQuote ? 6 : 4 },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Dispatch Name",
                                      color: "info",
                                      rules: [_vm.rules.alphaNumeric],
                                      "validate-on-blur": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "#1E88E5" } },
                                                [_vm._v(" mdi-contacts ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2179714314
                                    ),
                                    model: {
                                      value: _vm.capacity.dispatchName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.capacity,
                                          "dispatchName",
                                          $$v
                                        )
                                      },
                                      expression: "capacity.dispatchName",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("small", [_vm._v("*indicates required field")]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "info", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.submitCapacity()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }